import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { columns } from "./config/index";
import MediaForm from "./components/MediaForm";
import { DcMediaApi } from '@/api';
var statusList = [{
  id: '',
  name: '全部'
}, {
  id: '1',
  name: '正常'
}, {
  id: '2',
  name: '停用'
}];
export default {
  name: "dcmedia",
  components: {
    MediaForm: MediaForm
  },
  data: function data() {
    return {
      editId: '',
      loading: false,
      createForm: this.$form.createForm(this, {
        name: "whitelist_index"
      }),
      createFormValues: {},
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      isDeleteVisible: false,
      deleting: false,
      dataSource: [],
      tipsDesc: '',
      modalTitle: '新增/编辑',
      addVersionVisible: false,
      updateStatus: 0,
      checkItem: {}
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        switch (key) {
          case 'status':
            return statusList;
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    if (+this.$route.query.addType == 1) {
      this.createForm.resetFields();
      this.page = 1;
      this.size = 20;
    }
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var params = {
        size: this.size,
        page: this.page
      };
      DcMediaApi.newsConfigList(params).then(function (res) {
        _this.loading = false;
        _this.dataSource = res.list;
        _this.total = res.total;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function (e) {
        setTimeout(function () {
          _this.loading = false;
        }, 3000);
      });
    },
    clickEdit: function clickEdit(item) {
      this.editId = item.id;
      this.addVersionVisible = true;
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    showPopupForm: function showPopupForm() {
      this.editId = '';
      this.addVersionVisible = true;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    }
  }
};